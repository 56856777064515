import React, { useContext } from "react";
import "./Navbar.css";
import { Layout, Avatar, Dropdown } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { signOut } from "../../SSO/CognitoService";
import { UserContext } from "../../Context/UserContext/UserContext";
import { Link } from "react-router-dom";
import "../../index.css";

const { Header } = Layout;

// horizontal navbar (header of the page)
const items = [
  {
    key: "1",
    label: <a onClick={signOut}>Signout</a>,
  },
];

const NavBar = () => {
  const { user } = useContext(UserContext);

  function reorderName(name) {
    const nameParts = name.split(",").map((part) => part.trim());
    const reversedName = nameParts.reverse();
    const correctOrderName = reversedName.join(" ");
    return correctOrderName;
  }

  let userName = user.name;
  // let userName = "Vijay Kumar, Chatakondu";
  const formattedName = reorderName(userName);
  // const formattedName = userName.replace(
  //   /(\w+)\s*,\s*(\w+)/,
  //   (_, first, last) => `${last} ${first}`
  // );

  return (
    <Header className="navbar">
      <div className="logo">
        <div>
          <Link to="/">
            {" "}
            {/* <h2 style={{ color: "black" }}>H<span style={{color:"#f47920"}}>i</span>Cloud</h2> */}
            <h2 style={{ color: "#81bc01" }}>
              Devops<span style={{ color: "white" }}>-Xperience</span>
            </h2>
          </Link>
        </div>
      </div>
      <div className="avatar">
        <Dropdown
          style={{ width: "150px" }}
          menu={{
            items,
          }}
          placement="bottomRight"
          arrow
        >
          <a href="#">
            <Avatar size={30} icon={<UserOutlined />} />{" "}
            <h4> {formattedName} </h4>
          </a>
        </Dropdown>
      </div>
    </Header>
  );
};

export default NavBar;

import "../SideNav/SideNav.css";
import { Layout } from "antd";
import { React } from "react";
import { Route, Routes } from "react-router-dom";
import UserProfile from "../../Pages/UserProfile/UserProfile";
import InfraForm from "../../Pages/Create Infra/InfraForm";
import Creating from "../../Pages/Creating/Creating";
import { createPortal } from "react-dom";
import LeftSider from "../LeftSider/LeftSider";
import DeletionForm from "../../Pages/Delete Infra/DeletionForm";
import Deleting from "../../Pages/Deleting/Deleting";
import ProductDetails from "../../Pages/Product List/ProductDetails";
import AllProducts from "../../Pages/Product List/AllProducts";
import LogsPage from "../../Pages/Logs/LogsPage";
import ShowLogs from "../../Pages/DisplayLogs/ShowLogs";
import AdminDashboard from "../../Pages/Admin/AdminDashboard";
import AddGlobalAdmin from "../../Pages/UserManagement/AddGlobalAdmin";
import AddNewUser from "../../Pages/UserManagement/AddNewUser";
import AdminProductLists from "../../Pages/AdminProductLists/AdminProductLists";

const { Content } = Layout;

const SideNav = () => {
  return (
    <Layout
      style={{
        minHeight: "93.1vh",
        color: "#53565a",
        fontSize: "50px",
      }}
    >
      <Layout>
        <Content style={{ paddingTop: 15, minHeight: "100vh" }}>
          <MainContent />
          {createPortal(<LeftSider />, document.body)}
        </Content>
      </Layout>
    </Layout>
  );
};

// function to display the content in the main layout
function MainContent() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<UserProfile />} />
        <Route path="/create-infra" element={<InfraForm />} />
        <Route path="/creating" element={<Creating />} />
        <Route path="/delete-infra" element={<DeletionForm />} />
        <Route path="/all-products" element={<AllProducts />} />
        <Route path="/deleting" element={<Deleting />} />
        <Route path="/products-lists" element={<ProductDetails />} />
        <Route path="/logs" element={<LogsPage />} />
        <Route path="/show-logs" element={<ShowLogs />} />
        <Route path="/admin-dashboard" element={<AdminDashboard />} />
        <Route path="/add-admin" element={<AddGlobalAdmin />} />
        <Route path="/add-user-role" element={<AddNewUser />} />
        <Route path="/admin-products-list" element={<AdminProductLists />} />
      </Routes>
    </div>
  );
}

export default SideNav;
